<style>

.selectSociety {
    width: 455px !important;
    /* max-width: fit-content; */
}
</style>
<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalDriver" centered no-close-on-backdrop no-close-on-esc size="md"
            @ok="submitDriver">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Add Driver</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateFleet">
                        <b-row>
                            <b-col cols="12">
                                <!-- <b-form-group labe-for="Transporter" label="Transporter">
                                    <b-form-select size="md" v-model="driver.transporter_id">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="transporter in transporters" :key="transporter.id"
                                            :value="transporter.id">
                                            {{ transporter.name.toUpperCase() }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group> -->

                                <Dropdown v-model="driver.transporter_id" :options="transporters" filter optionLabel="name" optionValue="id" placeholder="Select a Transporter" class="w-full md:w-14rem selectSociety">
                                    <template #option="slotProps">
        <div class="flex align-items-center">
            <div>{{ slotProps.option.name }}</div>
        </div>
    </template>
                                </Dropdown>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="license" label="License">
                                    <validation-provider #default="{ errors }" name="license" rules="required">
                                        <b-form-input id="license" name="license" v-model="driver.license"
                                            :state="errors.length > 0 ? false : null" size="md" value="" type="number" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.license">{{
                                            serverErrors.license[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="mobile" label="Phone number">
                                    <validation-provider #default="{ errors }" name="mobile" rules="required">
                                        <b-form-input id="mobile" name="mobile" v-model="driver.mobile"
                                            :state="errors.length > 0 ? false : null" size="md" value="" type="number"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.mobile">{{
                                            serverErrors.mobile[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>

                            <b-col cols="4">
                                <b-form-group labe-for="firstName" label="First Name">
                                    <validation-provider #default="{ errors }" name="firstName" rules="required">
                                        <b-form-input id="firstName" name="firstName" v-model="driver.firstName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""
                                            v-on:keypress="isLetter($event)" type="text"
                                            oninvalid="setCustomValidity('Please enter on alphabets only. ')" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.firstName">{{
                                            serverErrors.firstName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="middleName" label="Middle Name">
                                    <validation-provider #default="{ errors }" name="middleName" rules="required">
                                        <b-form-input id="middleName" name="middleName" v-model="driver.middleName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""  v-on:keypress="isLetter($event)" type="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.middleName">{{
                                            serverErrors.middleName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="lastName" label="Last Name">
                                    <validation-provider #default="{ errors }" name="lastName" rules="required">
                                        <b-form-input id="lastName" name="lastName" v-model="driver.lastName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""  v-on:keypress="isLetter($event)" type="text"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.lastName">{{
                                            serverErrors.lastName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                           
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="gender" label="Gender" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="Gender" rules="">
                                        <b-form-select size="md" v-model="driver.gender" id="gender" name="gender"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option value="female">Female</b-form-select-option>
                                            <b-form-select-option value="male">Male</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{
                                            serverErrors.gender[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>


                    </b-form>
                    <!-- <pre>{{ driver }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeFleetsForm()">
                                <span class="text-nowrap">Add Driver</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(firstName)="data">
                    {{ data.item.person.firstName.toUpperCase() }}
                </template>

                <template #cell(middleName)="data">
                    {{ data.item.person.middleName }}
                </template>

                <template #cell(lastName)="data">
                    {{ data.item.person.lastName.toUpperCase() }}
                </template>

                <template #cell(gender)="data">
                    {{ data.item.person.gender.toUpperCase() }}
                </template>

                <template #cell(transporter)="data">
                    {{ data.item.transporter ? data.item.transporter.name.toUpperCase() : '' }}
                </template>




                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import driversStoreModule from '@/views/cromis/transportations/driver/driversStoreModule'
import useDriversList from '@/views/cromis/transportations/driver/useDriversList'
import Dropdown from 'primevue/dropdown';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,Dropdown,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalDriver = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const transporters = ref([])
        const selectedCompany = ref(null)

        const transporter = ref({
            id: null,
            company_id: null,
            tin: null,
            name: null,
            vrn: null,
            busines_licence: null,
        })

        const driver = ref({
            id: null,
            license: null,
            firstName: null,
            lastName: null,
            middleName: null,
            gender: null,
            transporter_id: null,
            driver: null
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-driver'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, driversStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-driver/transporters')
                .then(response => {
                    transporters.value = response.data.transporters
                    console.log(transporters.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useDriversList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            driver.value = {
                id: item.id,
                license: item.license,
                firstName: item.person.firstName,
                lastName: item.person.lastName,
                middleName: item.person.middleName,
                gender: item.person.gender,
                transporter_id: item.transporter.id,
                driver: item.driver
            }

            myModalDriver.value.show()
        }

        const invokeFleetsForm = () => {
            serverErrors.value = null

            driver.value = {
                id: null,
                license: null,
                firstName: null,
                lastName: null,
                middleName: null,
                gender: null,
                transporter_id: null,
                driver: null
            }
            myModalDriver.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            driver.value = {
                id: null,
                license: null,
                firstName: null,
                lastName: null,
                middleName: null,
                gender: null,
                transporter_id: null,
                driver: null
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (transporter.value.id === null || transporter.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }

        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); 
            if (/^[A-Za-z"'"]+$/.test(char)) return true; 
            else e.preventDefault();
        }

        const submitDriver = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null

            if (driver.value.id === null || driver.value.id === 0)
                handelDriverCreate()
            else
                handleUpdate(driver.value)

        }


        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()
            console.log(item)
            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-driver/update', { id: item.id, data: item })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false

                    myModalDriver.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Driver has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }


        const handelDriverCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-driver/create', driver.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The transporter Driver has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    driver.value = {
                        id: null,
                        license: null,
                        firstName: null,
                        lastName: null,
                        middleName: null,
                        gender: null,
                        transporter_id: null
                    }
                    myModalDriver.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = (item) => {
            context.root.$swal({
                title: 'Are you sure?',
                text: `Driver  will be deleted.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-outline-danger',
                    cancelButton: 'btn btn-outline-primary ml-1',
                },
                buttonsStyling: false,
            }).then(async result => {
                if (result.value) {
                    let success = await remove(item)

                    if(success){
                        context.root.$swal({
                            icon: 'info',
                            title: 'Deleted!',
                            text: 'Driver has been deleted.',
                            customClass: {
                            confirmButton: 'btn btn-outline-info',
                            },
                        })
                    }
                }
            })
        }

        const remove = async (id) => {
            let success = false

            await store.dispatch('cromis-driver/remove', id)
                        .then(response => {
                            success = true
                            refetch()
                        })
                        .catch(error => {
                            success = false
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: false,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        })

            return success
        }
        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            transporter,
            driver,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isLetter,
            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalDriver,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            submitDriver,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            transporters,
            selectedCompany,
            invokeFleetsForm
        }
    }
}
</script>

<style lang="scss" scoped>@import '@core/scss/vue/libs/vue-select.scss';</style>